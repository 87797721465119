import { useEffect, useState } from 'react';
import { URLS } from '../utils';
import { useScript } from './useScript';

const APPS_FLYER_ENABLED = false;
declare global {
  interface Window {
    AF_SMART_SCRIPT: {
      generateOneLinkURL: (params: object) => { clickURL: string };
    };
  }
}
export function useGetAppsFlyerURLs() {
  const oneLinkURL = 'https://cointracker.onelink.me/fu9j';
  const googleClickIdKey = 'af_sub1';
  const status = useScript(
    APPS_FLYER_ENABLED
      ? 'https://onelinksmartscript.appsflyer.com/onelink-smart-script-latest.js'
      : null,
  );
  const [iosUrl, setIosUrl] = useState<string>(URLS.APPLE_APP_STORE);
  const [androidUrl, setAndroidUrl] = useState<string>(URLS.GOOGLE_PLAY_STORE);
  useEffect(() => {
    const mediaSource = { keys: ['utm_source'], defaultValue: 'webapp' };
    const campaign = { keys: ['utm_campaign'], defaultValue: '' };
    const afSub2 = { keys: ['fbclid'], defaultValue: '' }; // Facebook clickid is placed in af_sub2
    const channel = { keys: ['utm_channel'], defaultValue: '' };
    if (status === 'ready' && APPS_FLYER_ENABLED) {
      //Call the function after embedding the code through a global parameter on the window object called window.AF_SMART_SCRIPT.
      //Onelink URL is generated.
      const result = window.AF_SMART_SCRIPT.generateOneLinkURL({
        oneLinkURL,
        afParameters: {
          mediaSource: mediaSource,
          campaign: campaign,
          googleClickIdKey,
          afSub2,
          channel,
        },
      });

      if (result) {
        setIosUrl(result.clickURL);
        setAndroidUrl(result.clickURL);
      }
    }
  }, [status]);

  return { iosUrl, androidUrl };
}

export default useGetAppsFlyerURLs;
